<template>
  <div class="" v-if="hasPerm('payments.view_invoice')">
    <page-header title="Exports Facturation" icon="fa fa-file-excel"></page-header>
    <div class="header-line">
      <date-range-selector @change="onDateRangeChanged"></date-range-selector>
    </div>
    <div class="export-block">
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <b>Exports</b>
          </b-col>
          <b-col cols="8">
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-file-invoice"></i> État des ventes</h3>
          </b-col>
          <b-col cols="8">
            Les ventes annulées et remboursées sont incluses. Les annulations non facturées sont exclues.
          </b-col>
          <b-col class="text-right" cols="2">
            <a
              href
              @click.prevent="downloadSalesAndCancellations"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col>
            <h3><i class="fa fa-credit-card"></i> Paiements</h3>
          </b-col>
          <b-col class="text-right">
            <a href @click.prevent="downloadPayments" class="btn btn-secondary" :class="{ disabled: !isDownloadActive }">
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="export-block" v-if="cielExport">
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <b>Exports CIEL</b>
          </b-col>
          <b-col cols="8">
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-file-invoice"></i> Ventes</h3>
          </b-col>
          <b-col cols="8">
            Les ventes annulées et remboursées sont incluses. Les annulations non facturées sont exclues.
          </b-col>
          <b-col class="text-right" cols="2">
            <a
              href
              @click.prevent="downloadCielSales"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col>
            <h3><i class="fa fa-credit-card"></i> Paiements</h3>
          </b-col>
          <b-col class="text-right">
            <a href @click.prevent="downloadCielPayments" class="btn btn-secondary" :class="{ disabled: !isDownloadActive }">
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="export-block">
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <b>Compatibilité</b>
          </b-col>
          <b-col cols="8">
            Exports pour compatibilité
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
       <b-row>
         <b-col cols="2">
           <h3><i class="fa fa-file-invoice"></i> Ventes</h3>
         </b-col>
         <b-col cols="8">
           Ventes facturées. Les ventes annulées et remboursées sont exclues.
         </b-col>
         <b-col class="text-right" cols="2">
           <a href @click.prevent="downloadSales" class="btn btn-secondary" :class="{ disabled: !isDownloadActive }">
             <i class="fa fa-file-excel"></i> Télécharger
           </a>
         </b-col>
       </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-file-invoice"></i> État des ventes (compatibilité)</h3>
          </b-col>
          <b-col cols="8">
            Les ventes annulées et remboursées sont incluses. Les annulations non facturées sont incluses.
          </b-col>
          <b-col class="text-right" cols="2">
            <a
              href
              @click.prevent="downloadSalesAndCancellationsCompat"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapMutations } from 'vuex'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { openDocument } from '@/utils/http'

export default {
  name: 'InvoicesExports',
  mixins: [BackendMixin],
  components: {
    PageHeader,
    DateRangeSelector,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
    }
  },
  mounted() {
  },
  computed: {
    isDownloadActive() {
      return this.startDate && this.endDate
    },
    cielExport() {
      return store.getters.config.cielExport
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async downloadSales() {
      const docUrl = '/export-ventes/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadSalesAndCancellations() {
      const docUrl = '/export-ventes-et-annulations/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadSalesAndCancellationsCompat() {
      const docUrl = '/export-ventes-et-annulations/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate + '--compat'
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadPayments() {
      const docUrl = '/export-paiements/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadCielSales() {
      const docUrl = '/export-ciel-ventes/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadCielPayments() {
      const docUrl = '/export-ciel-paiements/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
  },
}
</script>

<style scoped lang="less">
.export-line {
  padding: 5px 0;
  margin: 5px;
  border-bottom: solid 1px #ccc;
}
.export-line:last-of-type {
  padding: 5px 0;
  margin: 5px;
  border-bottom: none;
}
.export-block {
  margin-bottom: 50px;
  padding: 10px;
  background: #e0e0e0;
}
.export-block:last-of-type {
  margin-bottom: 0;
}
</style>
